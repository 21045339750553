/* Основной контейнер загрузчика */
.cafe-site-loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    transition: opacity 0.5s ease-in-out;
}

/* Контейнер для содержимого */
.cafe-site-loader-content {
    text-align: center;
}

/* Спиннер вокруг логотипа */
.cafe-site-loader-spinner {
    position: relative;
    display: inline-block;
    width: 200px;
    height: 200px;
    border-radius: 50%; /* Круглая форма */
}

.cafe-site-loader-spinner:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 5px dashed rgb(255, 183, 0); /* Цвет спиннера */
    border-top-color: transparent; /* Прозрачная верхняя часть */
    border-radius: 50%;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    animation: spin 1s linear infinite; /* Вращение спиннера */
}

/* Логотип */
.cafe-site-loader img {
    width: 150px;
    height: 150px;
    background-color: rgb(255, 183, 0);
    border-radius: 50%;
    position: relative;
    z-index: 1; /* Поверх спиннера */
    display: block;
    margin: auto; /* Центрирование логотипа */
    top: 25px; /* Смещение для центрирования внутри спиннера */
}

/* Текст */
.cafe-site-loader h2 {
    font-size: 1.5rem;
    color: #333;
    margin-top: 20px;
}

/* Анимация исчезновения */
.cafe-site-fade-out {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
}

/* Анимация вращения спиннера */
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
